import jsPDF from "jspdf";
import "jspdf-autotable";
import cloneDeep from "lodash.clonedeep";

export const printToPDF = (customItemData) => {
  const doc = new jsPDF({ orientation: "landscape" });

  doc.autoTableSetDefaults({
    headStyles: { fillColor: 0 },
  });

  /*
   * HEADER
   */
  doc.setFontSize(15);
  doc.text(`ICFY Work Room Order`, 14, 15);
  doc.setFontSize(12);
  doc.text(
    `Decorator: ${customItemData.u.first_name} ${customItemData.u.last_name}`,
    14,
    25
  );
  doc.text(`Customer Tag Name: ${customItemData.p.project_name}`, 14, 30);
  doc.text(`Room: ${customItemData.r.name}`, 14, 35);
  doc.text(
    `Date: ${new Date(
      customItemData.o.order_date * 1000
    ).toLocaleDateString()}`,
    200,
    25,
    null,
    null,
    "right"
  );
  doc.text(`PO: ${customItemData.o.order_id}`, 200, 30, null, null, "right");
  doc.text(`Sew:`, 200, 35, null, null, "right");

  // Drapery, and only drapery, requires customizations to the work order because reasons
  let finalDoc;
  if (customItemData.cpt.customProductName === "Drapery") {
    finalDoc = generateDraperyDetails(doc, customItemData);
  } else {
    finalDoc = generateNonDraperyDetails(doc, customItemData);
  }

  finalDoc.save(
    `PO_${customItemData.o.order_id}_${customItemData.cpt.customProductName}`
  );
};

const generateNonDraperyDetails = (doc, customItemData) => {
  doc.text(`${customItemData.cpt.customProductName} Work Order`, 14, 40);

  if (customItemData.cptf && customItemData.cptf.length > 0) {
    // Add custom item fields to table
    const tableColumns = customItemData.cptf.map((cptf) => cptf.field_name);
    const tableRows = customItemData.cptf.map((cptf) => cptf.field_value);

    // Additional non-custom-item fields
    const addFieldNamesToTable = [
      {
        field_name: "Quantity",
        field_value: Math.floor(Number(customItemData.pp.quantity)),
      },
    ];

    for (const field of addFieldNamesToTable) {
      tableColumns.push(field.field_name);
      tableRows.push(field.field_value);
    }

    doc.autoTable({ head: [tableColumns], body: [tableRows], startY: 50 });
  }

  const itemDetailsFinalY = doc.previousAutoTable.finalY; // this gives you the value of the end-y-axis-position of the previous autotable.

  doc.setFontSize(11);

  doc.text(
    `Sketch/Instructions`,
    200,
    itemDetailsFinalY + 10,
    null,
    null,
    "right"
  );

  let casedGoodsCurrentY = itemDetailsFinalY;

  for (const casedGood of customItemData.cg) {
    doc.text(`${casedGood.Category}`, 14, (casedGoodsCurrentY += 10));

    Object.keys(casedGood).map(
      (key) =>
        casedGood[key] &&
        doc.text(`${key}: ${casedGood[key]}`, 20, (casedGoodsCurrentY += 5))
    );
  }

  return doc;
};

const generateDraperyDetails = (doc, customItemData) => {
  const decorativeOrWorking = customItemData.cptf.find(
    (cptf) => cptf.field_name === "Working or Decorative"
  ).field_value;
  const type = customItemData.cptf.find(
    (cptf) => cptf.field_name === "Type"
  ).field_value;

  doc.text(
    `${customItemData.cpt.customProductName} Work Order - ${decorativeOrWorking} ${type}`,
    14,
    40
  );

  if (customItemData.cptf && customItemData.cptf.length > 0) {
    const customItemFieldTableData = customItemData.cptf.map((cptf) => {
      return { field_name: cptf.field_name, field_value: cptf.field_value };
    });

    // The people what make the curtains can write these in their own selves I tell you what
    const addFieldNamesToTable = [
      "Each Panel Face Width",
      "Plus Return",
      "Plus Ease & Overlap",
      "Hooks",
      "Folding Fan/Flat",
      "Quantity",
    ];

    // To save space on the drapery item details table, remove things that are not needed or that will be displayed elsewhere
    const excludeFieldNamesFromTable = [
      "Working or Decorative",
      "Type",
      "Add Lining",
      "Lining Colour",
      "Vertical Repeat",
      "Fabric Width",
    ];

    // Take some fields from the data that no longer appears in the item detail table and move it down into Fabric details
    const addFieldNamesToFabricDetails = [
      "Add Lining",
      "Lining Colour",
      "Vertical Repeat",
    ];

    // Fields to remove from fabric details
    const excludeFieldNamesFromFabricDetails = ["Category"];

    // delete the stored custom item data we don't want to show in the table
    const filteredCustomItemFieldTableData = customItemFieldTableData.filter(
      (cptf) => !excludeFieldNamesFromTable.includes(cptf.field_name)
    );

    // add the manual entry fields to the table data
    for (const field of addFieldNamesToTable) {
      filteredCustomItemFieldTableData.push({
        field_name: field,
        field_value:
          // So most of these additional field values are meant to be left blank and filled in by hand, but quantity we actually have
          field.toLowerCase() === "quantity"
            ? Math.floor(Number(customItemData.pp.quantity))
            : "",
      });
    }

    const tableColumns = filteredCustomItemFieldTableData.map(
      (cptf) => cptf.field_name
    );
    const tableRows = filteredCustomItemFieldTableData.map(
      (cptf) => cptf.field_value
    );

    doc.autoTable({ head: [tableColumns], body: [tableRows], startY: 50 });

    const itemDetailsFinalY = doc.previousAutoTable.finalY; // this gives you the value of the end-y-axis-position of the previous autotable.

    doc.setFontSize(11);

    doc.text(
      `Sketch/Instructions`,
      200,
      itemDetailsFinalY + 10,
      null,
      null,
      "right"
    );

    let casedGoodsCurrentY = itemDetailsFinalY;

    const additionalFabricFields = customItemFieldTableData.filter((cptf) =>
      addFieldNamesToFabricDetails.includes(cptf.field_name)
    );

    for (const casedGood of customItemData.cg) {
      // something, somewhere has marked our cased item data object as not extensible
      // so let's clone it to hack in some fabric details!
      const clonedCasedGood = cloneDeep(casedGood);

      if (clonedCasedGood.Category === "Fabric") {
        // for (const field of excludeFieldNamesFromFabricDetails) {

        // }
        for (const field of additionalFabricFields) {
          clonedCasedGood[field.field_name] = field.field_value;
        }
      }

      doc.text(`${clonedCasedGood.Category}`, 14, (casedGoodsCurrentY += 10));

      Object.keys(clonedCasedGood)
        .filter((key) => !excludeFieldNamesFromFabricDetails.includes(key))
        .map(
          (key) =>
            clonedCasedGood[key] &&
            doc.text(
              `${key}: ${clonedCasedGood[key]}`,
              20,
              (casedGoodsCurrentY += 5)
            )
        );
    }

    return doc;
  }
};
