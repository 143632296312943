import React, { useEffect } from "react";
import { token } from "../users/usersSlice";
import { getOrderDetails, orderItemList } from "./orderslice";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
  titleText: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
}));

export const PurchaseOrderDetails = (props) => {
  const orderId = props.match.params.id;
  const tokenVal = useSelector(token);

  const dispatch = useDispatch();
  const orderList = useSelector(orderItemList);
  const orderDetails = useSelector((state) => state.orders.orderData[orderId]);

  useEffect(() => {
    dispatch(getOrderDetails({ tokenVal, orderId }));
  }, [dispatch, orderId, tokenVal]);

  const classes = useStyles1();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({}))(TableRow);

  const renderComponent = (
    <>
      <Typography variant="h4" className={classes.titleText} noWrap>
        Purchase Orders
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Purchase Order Details">
          <TableHead>
            <TableRow>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell>Item</StyledTableCell>
              <StyledTableCell>Colour</StyledTableCell>
              <StyledTableCell align="right">Quantity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList && Object.keys(orderList).length > 0 ? (
              Object.keys(orderList).map((key) => (
                <React.Fragment key={orderList[key].project_product_id}>
                  <StyledTableRow key={orderList[key].project_product_id}>
                    <StyledTableCell>
                      {orderList[key].supplier_name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {orderList[key].item_name}
                    </StyledTableCell>
                    <StyledTableCell>{orderList[key].colour}</StyledTableCell>
                    <StyledTableCell align="right">
                      {orderList[key].quantity}
                    </StyledTableCell>
                  </StyledTableRow>
                  {orderList[key].cpp_name && (
                    <StyledTableRow>
                      <StyledTableCell>
                        {orderList[key].cpp_supplier}{" "}
                        {`(${orderList[key].supplier_name} - ${orderList[key].item_name})`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {orderList[key].cpp_name}{" "}
                        {`(${orderList[key].supplier_name} - ${orderList[key].item_name})`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {orderList[key].cpp_colour}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {orderList[key].cpp_quantity}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}
            {orderDetails &&
              orderDetails.order_notes &&
              orderDetails.order_notes.length > 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>
                    Notes: {orderDetails.order_notes}
                  </StyledTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return renderComponent;
};
