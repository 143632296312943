import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  componentSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
  },
}))(TableCell);

export const ItemDetails = ({ customItemData }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">
        {customItemData.cpt.customProductName}
      </Typography>
      <TableContainer className={classes.componentSpacing}>
        <Table
          className={classes.table}
          aria-label="custom item details"
          size="small"
        >
          <TableHead>
            <TableRow>
              {customItemData.cptf && customItemData.cptf.length > 0 ? (
                customItemData.cptf.map((cptf) => {
                  return (
                    <StyledTableCell
                      key={`${cptf.field_name}${cptf.field_value}`}
                    >
                      {cptf.field_name}
                    </StyledTableCell>
                  );
                })
              ) : (
                <></>
              )}
              <StyledTableCell>Quantity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {customItemData.cptf && customItemData.cptf.length > 0 ? (
                customItemData.cptf.map((cptf) => {
                  return (
                    <TableCell key={`${cptf.field_name}${cptf.field_value}`}>
                      {cptf.field_value}
                    </TableCell>
                  );
                })
              ) : (
                <></>
              )}
              <TableCell>
                {Math.floor(Number(customItemData.pp.quantity))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
