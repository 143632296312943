import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  Button,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  isLoading,
  getProductItemDetails,
  productItem,
  addItemToRoom,
  addItemToWindow,
} from "./productItemSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Currency } from "../../shared/currency/Currency";
import { addCasedItemToCustomItem } from "../items/customItems/customItemSlice";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disclaimer: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const getAdditionalDetails = (item) => {
  let additionalDetails = null;

  switch (item.category) {
    case "Fabric":
      additionalDetails = {
        fabric_vertical_repeat: item.fabric_vertical_repeat,
        fabric_width: item.fabric_width,
      };
      break;
    default: // Cased goods and hardware would be added here
      break;
  }

  return additionalDetails;
};

export const ProductItemDetails = (props) => {
  const { id } = props.match.params;

  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const item = useSelector(productItem);
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const [invalidQuantity, setInvalidQuantity] = useState(false);

  const [fieldData, setFieldData] = useState({ quantity: 1 });
  const selectedInput = useRef();

  const { search } = useLocation();
  const parsedQuerystring = queryString.parse(search);
  const { roomId, windowId, customItem } = parsedQuerystring;
  const homePageSearch = !Object.keys(parsedQuerystring).length;

  let backToSearchPath = "";

  if (homePageSearch) {
    backToSearchPath = `/browse`;
  } else if (customItem) {
    if (roomId) {
      backToSearchPath = `/fabric-search?roomId=${roomId}&customItem=true`;
    } else {
      backToSearchPath = `/fabric-search?windowId=${windowId}&customItem=true`;
    }
  } else {
    if (roomId) {
      backToSearchPath = `/search?roomId=${roomId}`;
    } else {
      backToSearchPath = `/search?windowId=${windowId}`;
    }
  }

  const classes = useStyles();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    dispatch(getProductItemDetails({ tokenVal, id }));
  }, [dispatch, tokenVal, id]);

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const saveItem = (e) => {
    e.preventDefault();

    if (customItem) {
      const additionalDetails = getAdditionalDetails(item);

      dispatch(
        addCasedItemToCustomItem({
          ...fieldData,
          item_id: id,
          name: item.name,
          category: item.category,
          wholesale_price: item.wholesale_price,
          ...additionalDetails, // Category specific fields
        })
      );
    } else {
      if (roomId) {
        dispatch(
          addItemToRoom({
            tokenVal,
            addedItem: {
              ...fieldData,
              item_id: id,
              room_id: roomId,
              name: item.name,
              description: item.description,
              wholesale_price: item.wholesale_price,
            },
          })
        );
      } else if (windowId) {
        dispatch(
          addItemToWindow({
            tokenVal,
            addedItem: {
              ...fieldData,
              item_id: id,
              window_id: windowId,
              name: item.name,
              description: item.description,
              wholesale_price: item.wholesale_price,
            },
          })
        );
      }
    }
  };

  if (shouldRedirectVal === "search") {
    return (
      <Redirect
        exact
        to={
          roomId ? `/search?roomId=${roomId}` : `/search?windowId=${windowId}`
        }
      />
    );
  }
  if (shouldRedirectVal === "custom-item") {
    return (
      <Redirect
        exact
        to={
          roomId
            ? `/custom-item?roomId=${roomId}&step=1`
            : `/custom-item?windowId=${windowId}&step=1`
        }
      />
    );
  }

  const renderComponent =
    loading === "pending" || !item ? (
      <CircularProgress />
    ) : (
      <>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.titleText} noWrap>
            {item.name}
          </Typography>
        </div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Description</TableCell>
              <TableCell>{item.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Supplier</TableCell>
              <TableCell>{item.supplier}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Category</TableCell>
              <TableCell>{item.category}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Item Number</TableCell>
              <TableCell>{item.item_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Unit of Measure</TableCell>
              <TableCell>{item.unit_of_measure}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Retail Price</TableCell>
              <TableCell>
                <Currency number={item.retail_price} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Wholesale Price</TableCell>
              <TableCell>
                <Currency number={item.wholesale_price} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Shipping</TableCell>
              <TableCell>{item.shipping}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div>
          <Typography
            className={classes.disclaimer}
            variant="body2"
            paragraph={true}
          >
            Prices are subject to change until time of purchase
          </Typography>
        </div>

        <form autoComplete="off" onSubmit={saveItem}>
          {!customItem && !homePageSearch && (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                label="Quantity"
                type="number"
                defaultValue={1}
                error={invalidQuantity}
                helperText={
                  invalidQuantity && "Quantity must be a whole number"
                }
                onChange={(e) => {
                  //quantity must be a whole number for everything but fabric
                  setInvalidQuantity(
                    e.target.value % 1 !== 0 && item.category !== "Fabric"
                  );
                  setFieldData({ ...fieldData, quantity: e.target.value });
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    step: item.category === "Fabric" ? 0.25 : 1,
                  },
                }}
                required
              />
            </>
          )}

          <div>
            {!homePageSearch && (
              <>
                {item.category === "Fabric" && (
                  <div>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Colour"
                      onChange={(e) =>
                        setFieldData({ ...fieldData, colour: e.target.value })
                      }
                      required
                      InputProps={{ inputProps: { maxLength: 200 } }}
                    />
                  </div>
                )}
                {item.notes && (
                  <div>
                    <TextField
                      className={classes.spacing}
                      id="additional-details"
                      label="Additional Details"
                      multiline
                      rows={4}
                      placeholder={item.notes}
                      variant="outlined"
                      required
                      onChange={(e) =>
                        setFieldData({ ...fieldData, notes: e.target.value })
                      }
                    />
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  aria-label="Add Item"
                  className={classes.buttonSpacing}
                >
                  Add Item
                </Button>
              </>
            )}

            <Button
              variant="contained"
              color="primary"
              aria-label="Back to Search"
              className={classes.buttonSpacing}
              component={Link}
              to={backToSearchPath}
            >
              Back to Search
            </Button>
          </div>
        </form>
      </>
    );

  return renderComponent;
};
