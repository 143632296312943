import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isLoading, error, addWindow, editWindow } from "./windowSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Redirect, Link } from "react-router-dom";
import { ItemList } from "../items/ItemList";

export const validForm = (fieldData) => {
  return fieldData.name;
};

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
  },
  nameTextBox: {
    marginTop: "20px",
  },
  button: {
    margin: "15px 0",
  },
}));

export const Window = (props) => {
  const { id } = props.match.params;
  const { edit } = props;
  const classes = useStyles();

  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const hasError = useSelector(error);

  const roomWindow = useSelector((state) => state.windows.windowData[id]);

  const [fieldData, setFieldData] = useState({});
  const selectedInput = useRef();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const submitWindow = (e) => {
    e.preventDefault();

    if (edit) {
      dispatch(editWindow({ tokenVal, id, roomWindow: fieldData }));
    } else {
      dispatch(addWindow({ tokenVal, id, roomWindow: fieldData }));
    }
  };

  if (shouldRedirectVal === "room") {
    return <Redirect exact to={`/room/edit/${id}`} />;
  }

  let renderComponent = "";

  if (loading === "pending") {
    renderComponent = <CircularProgress />;
  } else {
    renderComponent = (
      <div>
        <Typography variant="h4" className={classes.titleText} noWrap>
          {edit ? roomWindow.name : "New Window"}
        </Typography>
        {hasError && <div>Something went wrong!</div>}
        <form autoComplete="off" onSubmit={submitWindow}>
          <div>
            <TextField
              className={classes.nameTextBox}
              inputRef={selectedInput}
              required
              label="Window name"
              onChange={(e) =>
                setFieldData({ ...fieldData, name: e.target.value })
              }
              defaultValue={edit ? roomWindow.name : ""}
            ></TextField>
          </div>
          <div>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              aria-label={edit ? "Update Window" : "Add Window"}
              disabled={!validForm(fieldData)}
            >
              {edit ? "Update Window" : "Add Window"}
            </Button>
          </div>
          {edit && (
            <>
              <ItemList itemIds={roomWindow.items} />
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                aria-label="Add Items"
                component={Link}
                to={`/search?windowId=${id}`}
              >
                Add Items
              </Button>
            </>
          )}
        </form>
      </div>
    );
  }

  return renderComponent;
};
