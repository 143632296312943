import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { Header } from "./shared/Header";
import { ItemDetails } from "./shared/ItemDetails";
import { CasedGoodDetails } from "./shared/CasedGoodDetails";
import { token } from "../../users/usersSlice";
import {
  getCustomItemById,
  adminCustomItemData,
  isLoading,
} from "../adminSlice";
import { printToPDF } from "../utils/icfyWorkOrderGenerator";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  componentSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fabric: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  sketch: {
    width: "50%",
  },
  printButton: {
    float: "right",
  },
}));

const SketchInstructions = () => {
  const classes = useStyles();

  return (
    <>
      <Typography>Sketch/Instructions: WORKING</Typography>
      <Paper className={classes.componentSpacing}></Paper>
    </>
  );
};

export const ICFYWorkOrderPrintWrapper = React.forwardRef((props, ref) => {
  const { id } = props.match.params;
  const tokenVal = useSelector(token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomItemById({ tokenVal, customItemId: id }));
  }, [dispatch, tokenVal, id]);

  const customItemData = useSelector(adminCustomItemData);
  const loading = useSelector(isLoading);

  return loading === "pending" || Object.keys(customItemData).length === 0 ? (
    <CircularProgress />
  ) : (
    <>
      <Button
        color="secondary"
        variant="contained"
        style={{ float: "right" }}
        onClick={() => printToPDF(customItemData)}
      >
        Print work order
      </Button>
      <Typography variant="h4" noWrap>
        ICFY Work Room Order
      </Typography>
      <ICFYWorkOrder customItemData={customItemData} />
    </>
  );
});

export const ICFYWorkOrder = ({ customItemData }) => {
  const classes = useStyles();

  return (
    <>
      <Header customItemData={customItemData} />
      <ItemDetails customItemData={customItemData} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <div className={classes.fabric}>
          {customItemData.cg.map((casedGood, i) => (
            <CasedGoodDetails key={i} casedGood={casedGood} />
          ))}
        </div>
        <div className={classes.sketch}>
          <SketchInstructions />
        </div>
      </Grid>
    </>
  );
};
