import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const projects = await fetch(
        `${process.env.REACT_APP_API_URL}/getProjects`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              dispatch({ type: "invalidToken" });
              throw new Error("invalid token");
            }

            throw new Error("getProjects failed");
          } else {
            return res.json();
          }
        })
        .then((result) => {
          return flattenProjects(result);
        });

      return projects;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getProjectDetails = createAsyncThunk(
  "projects/getProjectDetails",
  async (data, { dispatch }) => {
    const { tokenVal, id } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const projectDetails = await fetch(
        `${process.env.REACT_APP_API_URL}/getProject/${id}`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              dispatch({ type: "invalidToken" });
              throw new Error("invalid token");
            }

            throw new Error("getProjectDetails failed");
          } else {
            return res.json();
          }
        })
        .then((result) => {
          return flattenProjectDetails(result);
        });

      return projectDetails;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const addProject = createAsyncThunk(
  "projects/addProject",
  async (data, { dispatch }) => {
    const { tokenVal, addedProject } = data;

    const project = {
      name: addedProject.name,
      line_1: `${addedProject.number} ${addedProject.street}`,
      line_2: addedProject.unit || "",
      city: addedProject.city,
      province: "ON",
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newProject = await fetch(
        `${process.env.REACT_APP_API_URL}/createProject`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(project),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("addProject failed");
        } else {
          return res.json();
        }
      });

      return {
        project_id: newProject.projectId,
        address_id: newProject.addressId,
        status_id: 1,
        name: project.name,
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projectData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [getProjects.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.projectData = action.payload.entities.projects;
    },
    [getProjects.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getProjects.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getProjectDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      const projectDetails = action.payload.entities.project;
      state.projectData = { ...state.projectData, ...projectDetails };
    },
    [getProjectDetails.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getProjectDetails.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [addProject.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.projectData[action.payload.project_id] = action.payload;
    },
    [addProject.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [addProject.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

const flattenProjectDetails = (result) => {
  const items = new schema.Entity(
    "items",
    {},
    { idAttribute: "project_product_id" }
  );
  const windows = new schema.Entity(
    "windows",
    { items: [items] },
    { idAttribute: "window_id" }
  );
  const rooms = new schema.Entity(
    "rooms",
    { windows: [windows], items: [items] },
    { idAttribute: "room_id" }
  );
  const flattenedProject = new schema.Entity(
    "project",
    { rooms: [rooms] },
    { idAttribute: "project_id" }
  );

  const flattenedResult = normalize(result, flattenedProject);

  return flattenedResult;
};

const flattenProjects = (result) => {
  const project = new schema.Entity(
    "projects",
    {},
    { idAttribute: "project_id" }
  );
  const projectList = new schema.Array(project);

  const flattenedResult = normalize(result, projectList);

  return flattenedResult;
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const projectList = (state) => state.projects.projectData;
export const isLoading = (state) => state.projects.loading;
export const error = (state) => state.projects.error;

export default projectsSlice.reducer;
