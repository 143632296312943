import { TextField, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useRef, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

export const validForm = (fieldData) => {
  return (
    fieldData.first_name &&
    fieldData.last_name &&
    fieldData.email &&
    fieldData.password &&
    fieldData.password === fieldData.password2
  );
};

export function Register() {
  const [fieldData, setFieldData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const selectedInput = useRef();
  const classes = useStyles();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  }, []);

  const createUser = async (user) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/createUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 201) {
            setSuccess(true);
          } else {
            setError(res);
          }
        });
    } catch (error) {
      setError(true);
    }
  };

  const register = (e) => {
    e.preventDefault();

    createUser(fieldData);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3" noWrap>
        Stride Studio
      </Typography>

      <Box m={5}>
        <Typography variant="h4" noWrap>
          Register
        </Typography>
        {success ? (
          <Typography className={classes.spacing}>
            You have successfully registered. Your account will need to be
            approved by an administrator before you can log in.
          </Typography>
        ) : (
          <form autoComplete="off" onSubmit={register}>
            <div>
              <TextField
                variant="outlined"
                className={classes.spacing}
                required
                label="First Name"
                onChange={(e) =>
                  setFieldData({ ...fieldData, first_name: e.target.value })
                }
                inputRef={selectedInput}
              ></TextField>
            </div>
            <div>
              <TextField
                variant="outlined"
                className={classes.spacing}
                required
                label="Last Name"
                onChange={(e) =>
                  setFieldData({ ...fieldData, last_name: e.target.value })
                }
              ></TextField>
            </div>
            <div>
              <TextField
                variant="outlined"
                className={classes.spacing}
                required
                label="Email"
                onChange={(e) =>
                  setFieldData({ ...fieldData, email: e.target.value })
                }
              ></TextField>
            </div>
            <div>
              <TextField
                variant="outlined"
                className={classes.spacing}
                required
                type="password"
                label="Choose a Password"
                onChange={(e) =>
                  setFieldData({ ...fieldData, password: e.target.value })
                }
              ></TextField>
            </div>
            <div>
              <TextField
                variant="outlined"
                className={classes.spacing}
                required
                type="password"
                label="Confirm Password"
                onChange={(e) =>
                  setFieldData({ ...fieldData, password2: e.target.value })
                }
              ></TextField>
            </div>
            <div>
              <TextField
                className={classes.spacing}
                id="outlined-multiline-static"
                label="Notes"
                multiline
                rows={4}
                placeholder="Notes for the Stride Design team"
                variant="outlined"
                onChange={(e) =>
                  setFieldData({ ...fieldData, notes: e.target.value })
                }
              />
            </div>
            <div>
              <Button
                type="submit"
                className={classes.spacing}
                variant="contained"
                color="primary"
                aria-label="Register"
                disabled={!validForm(fieldData)}
              >
                Register
              </Button>
            </div>
            {error && <Typography>{error}</Typography>}
          </form>
        )}
      </Box>
    </div>
  );
}
