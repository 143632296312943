import { createSlice } from "@reduxjs/toolkit";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    checkoutItemData: [],
    loading: "idle",
    error: null,
  },
  reducers: {
    addCheckoutItem(state, action) {
      state.checkoutItemData = [...state.checkoutItemData, action.payload];
    },
    removeCheckoutItem(state, action) {
      state.checkoutItemData = state.checkoutItemData.filter(
        (item) => item !== action.payload
      );
    },
    uncheckAllCheckoutItems(state, action) {
      state.checkoutItemData = [];
    },
    checkAllCheckoutItems(state, action) {
      state.checkoutItemData = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = checkoutSlice;
// Extract and export each action creator by name
export const {
  addCheckoutItem,
  removeCheckoutItem,
  uncheckAllCheckoutItems,
  checkAllCheckoutItems,
} = actions;

export const checkoutItems = (state) => state.checkout.checkoutItemData;

// Export the reducer, either as a default or named export
export default reducer;
