import { createSlice } from "@reduxjs/toolkit";

const redirectSlice = createSlice({
  name: "redirect",
  initialState: {
    redirectTo: null,
  },
  reducers: {
    resetRedirect: (state) => {
      state.redirectTo = null;
    },
  },
  extraReducers: {
    "projects/addProject/fulfilled": (state, action) => {
      state.redirectTo = "projectList";
    },
    "rooms/addRoom/fulfilled": (state, action) => {
      state.redirectTo = "project";
    },
    "windows/addWindow/fulfilled": (state, action) => {
      state.redirectTo = "room";
    },
    "items/updateProjectCasedItem/fulfilled": (state, action) => {
      const redirectDestination = !action.payload.room_id ? "window" : "room";
      state.redirectTo = redirectDestination;
    },
    "productItems/addItemToRoom/fulfilled": (state, action) => {
      state.redirectTo = "search";
    },
    "productItems/addItemToWindow/fulfilled": (state, action) => {
      state.redirectTo = "search";
    },
    "admin/updateOrderStatus/fulfilled": (state, action) => {
      state.redirectTo = "adminOrders";
    },
    "projectItems/updateProjectCasedItem/fulfilled": (state, action) => {
      state.redirectTo = "project";
    },
    "customItem/addCasedItemToCustomItem": (state, action) => {
      // only redirect if we're not adding cased goods direcltly from the custom flow
      if (!action.payload.custom_fabric_source) {
        state.redirectTo = "custom-item";
      }
    },
    "admin/updateUser/fulfilled": (state, action) => {
      state.redirectTo = "users";
    },
    "suppliers/createSupplier/fulfilled": (state, action) => {
      state.redirectTo = "manageSuppliers";
    },
    "suppliers/updateSupplier/fulfilled": (state, action) => {
      state.redirectTo = "manageSuppliers";
    },
  },
});

export const shouldRedirect = (state) => state.redirect.redirectTo;
export const { resetRedirect } = redirectSlice.actions;

export default redirectSlice.reducer;
