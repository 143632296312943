import React from "react";

export const Currency = ({ number }) => {
  const numberWithCommas = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const removeDollarSign = number?.toString().replace("$", "");

  const numberWithFixedDecimals = Number(removeDollarSign).toFixed(2);

  const formattedNumber = numberWithCommas(numberWithFixedDecimals);

  return <>${formattedNumber}</>;
};

export default Currency;
