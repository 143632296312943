import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const updateProjectCasedItem = createAsyncThunk(
  "projectItems/updateProjectCasedItem",
  async (data, { dispatch }) => {
    const { tokenVal, updatedItemVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateCasedItem`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(updatedItemVal),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("update failed");
        }
      });

      return updatedItemVal;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const projectItemsSlice = createSlice({
  name: "projectItems",
  initialState: {
    itemData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    "projects/getProjectDetails/fulfilled": (state, action) => {
      state.itemData = action.payload.entities.items || {};
    },
    "productItems/addItemToRoom/fulfilled": (state, action) => {
      state.itemData[action.payload.project_product_id] = action.payload;
    },
    "productItems/addItemToWindow/fulfilled": (state, action) => {
      state.itemData[action.payload.project_product_id] = action.payload;
    },
    [updateProjectCasedItem.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.itemData[action.payload.project_product_id] = {
        ...state.itemData[action.payload.project_product_id],
        ...action.payload,
      };
    },
    [updateProjectCasedItem.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [updateProjectCasedItem.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

export const itemList = (state) => state.projectItems.itemData;
export const isLoading = (state) => state.projectItems.loading;
export const error = (state) => state.projectItems.error;

export default projectItemsSlice.reducer;
