import React, { useEffect } from "react";
import { Button, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { projectList, getProjects } from "./projectsSlice";
import { token } from "../users/usersSlice";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const ProjectList = (props) => {
  const tokenVal = useSelector(token);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getProjects({ tokenVal }));
  }, [dispatch, tokenVal]);

  const projects = useSelector(projectList);
  // Order projects from newest to oldest
  // We didn't have the foresight to put a created date on projects, so we'll just assume biggest ID is the newest 🫠
  const orderedProjectIds = Object.keys(projects).sort((a, b) => b - a);

  const renderComponent = (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          Projects
        </Typography>
        <Button
          component={RouterLink}
          to={`/browse`}
          color="primary"
          variant="contained"
          aria-label="Search"
          className={classes.buttonSpacing}
        >
          Search
        </Button>
        <Button
          component={RouterLink}
          to={`/project/add`}
          color="primary"
          variant="contained"
          aria-label="Add Project"
          className={classes.buttonSpacing}
        >
          Add Project
        </Button>
      </div>
      {orderedProjectIds.map((key) => (
        <React.Fragment key={`project-${key}`}>
          <Typography variant="h6" noWrap>
            <Link
              component={RouterLink}
              to={`/project/${projects[key].project_id}`}
            >
              {projects[key].name}
            </Link>
          </Typography>
        </React.Fragment>
      ))}
    </>
  );

  return renderComponent;
};
