import React from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Link,
  IconButton,
} from "@material-ui/core";
import { Instagram, Facebook } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
  },
  footerItem: {
    display: "inline-block",
  },
  customizeToolbar: {
    minHeight: 20,
  },
}));

export function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <AppBar position="static" color="primary">
        <Toolbar className={classes.customizeToolbar}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <div>
              <div className={classes.footerItem}>
                <Typography variant="body1">
                  <Link
                    style={{ padding: "12px" }}
                    color="inherit"
                    href={`mailto:info@stridedesign.ca`}
                  >
                    Contact
                  </Link>
                </Typography>
              </div>
              <div className={classes.footerItem}>
                <Typography variant="body1">
                  <Link
                    style={{ padding: "12px" }}
                    color="inherit"
                    href={`http://stridedesign.ca`}
                    target="_blank"
                  >
                    Website
                  </Link>
                </Typography>
              </div>
              <div className={classes.footerItem}>
                <IconButton
                  color="inherit"
                  aria-label="Linkedin.com"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/stridedesigncollective/"
                    )
                  }
                >
                  <Instagram />
                </IconButton>
              </div>
              <div className={classes.footerItem}>
                <IconButton
                  color="inherit"
                  aria-label="Linkedin.com"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/stridedesigncollective/"
                    )
                  }
                >
                  <Facebook />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
