import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCustomItemOptionFields = createAsyncThunk(
  "customItem/getCustomItemOptionFields",
  async (data, { dispatch }) => {
    const { tokenVal, customItemTypeId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const customItemOptionFields = await fetch(
        `${process.env.REACT_APP_API_URL}/getCustomItemOptionFields/${customItemTypeId}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getCustomItemOptionFields failed");
        } else {
          return res.json();
        }
      });

      return customItemOptionFields;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getCustomItemTypes = createAsyncThunk(
  "customItem/getCustomItemTypes",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const customItemTypes = await fetch(
        `${process.env.REACT_APP_API_URL}/getCustomItemTypes`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getCustomItemTypes failed");
        } else {
          return res.json();
        }
      });

      return customItemTypes;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const createCustomItem = createAsyncThunk(
  "customItem/createCustomItem",
  async (data, { dispatch }) => {
    const { tokenVal, customItemValues, quote, yardage } = data;

    const customItemWithQuote = {
      ...customItemValues,
      quote,
      yardage,
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newCustomItem = await fetch(
        `${process.env.REACT_APP_API_URL}/createCustomItem`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(customItemWithQuote),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("createCustomItem failed");
        } else {
          return res.json();
        }
      });

      return { custom_item_id: newCustomItem.insertId };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const createCustomItemQuote = createAsyncThunk(
  "customItem/createCustomItemQuote",
  async (data, { dispatch }) => {
    const { tokenVal, customItemValues } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const quoteData = await fetch(
        `${process.env.REACT_APP_API_URL}/createCustomItemQuote`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(customItemValues),
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("createCustomItemQuote failed");
        } else {
          return res.json();
        }
      });

      return quoteData;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

const customItemSlice = createSlice({
  name: "customItem",
  initialState: {
    customItemData: {
      casedGoods: [],
    },
    customItemQuote: null,
    customItemOptionFields: [],
    customItemTypes: {},
    loading: "idle",
    error: null,
  },
  reducers: {
    updateCustomItem(state, action) {
      // if we're just updating the notes, don't reset the quote
      if (!action.payload.hasOwnProperty("notes")) {
        state.customItemQuote = null;
      }

      state.customItemData = {
        ...state.customItemData,
        ...action.payload,
      };
    },
    resetCustomItemFabrics(state) {
      state.customItemData.casedGoods = state.customItemData.casedGoods.filter(
        (item) => item.category !== "Fabric"
      );

      state.customItemQuote = null;
    },
    addCasedItemToCustomItem(state, action) {
      state.customItemData.casedGoods = [
        ...state.customItemData.casedGoods,
        action.payload,
      ];

      state.customItemQuote = null;
    },
    resetCustomItem(state, action) {
      state.customItemData = {
        ...action.payload,
        casedGoods: [],
      };
      state.customItemOptionFields = [];
      state.customItemQuote = null;
    },
    deleteCustomItem(state, action) {
      state.customItemData = {};
      state.customItemOptionFields = [];
      state.customItemQuote = null;
    },
  },
  extraReducers: {
    [getCustomItemOptionFields.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.customItemOptionFields = action.payload;
    },
    [getCustomItemOptionFields.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getCustomItemOptionFields.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
      state.customItemOptionFields = [];
    },
    [getCustomItemTypes.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.customItemTypes = action.payload;
    },
    [getCustomItemTypes.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getCustomItemTypes.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [createCustomItem.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.customItemData = {};
    },
    [createCustomItem.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [createCustomItem.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [createCustomItemQuote.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.customItemQuote = action.payload;
    },
    [createCustomItemQuote.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [createCustomItemQuote.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = customItemSlice;
// Extract and export each action creator by name
export const {
  updateCustomItem,
  resetCustomItem,
  deleteCustomItem,
  addCasedItemToCustomItem,
  resetCustomItemFabrics,
} = actions;

export const customItem = (state) => state.customItem.customItemData;
export const customItemQuote = (state) => state.customItem.customItemQuote;
export const customItemOptionFields = (state) =>
  state.customItem.customItemOptionFields;

export const customItemTypes = (state) => state.customItem.customItemTypes;

// Export the reducer, either as a default or named export
export default reducer;
